import { Controller } from "@hotwired/stimulus"

const CSS_CLASSES = {
  active: "is--active",
  showing: "is--showing"
}

export default class extends Controller {
  static targets = [
    "loginPanel",
    "signupPanel",
  ]

  connect() {
    this.loginTargetFunction = this.showLogin.bind(this)
    this.signupTargetFunction = this.showSignup.bind(this)
  }

  switchToLogin() {
    this.signupPanelTarget.addEventListener("transitionend", this.loginTargetFunction)
    this.signupPanelTarget.classList.remove(CSS_CLASSES.showing)
  }

  showLogin() {
    this.signupPanelTarget.removeEventListener("transitionend", this.loginTargetFunction)
    this.signupPanelTarget.classList.remove(CSS_CLASSES.active)
    this.loginPanelTarget.classList.add(CSS_CLASSES.active)
    this.loginPanelTarget.classList.add(CSS_CLASSES.showing)
  }

  switchToSignup() {
    this.loginPanelTarget.addEventListener("transitionend", this.signupTargetFunction)
    this.loginPanelTarget.classList.remove(CSS_CLASSES.showing)
  }

  showSignup() {
    this.loginPanelTarget.removeEventListener("transitionend", this.signupTargetFunction)
    this.loginPanelTarget.classList.remove(CSS_CLASSES.active)
    this.signupPanelTarget.classList.add(CSS_CLASSES.active)
    this.signupPanelTarget.classList.add(CSS_CLASSES.showing)
  }
}
