import { Controller } from "@hotwired/stimulus"
import Flickity from "flickity"

const CSS_CLASSES = {
  initialized: "is--initialized",
}

const DEFAULT_AUTOPLAY_DELAY = 5_000
const DEFAULT_ALIGNMENT = "center"

export default class extends Controller {
  static targets = ["slider"]
  static values = {
    autoplayDelay: Number,
    alignment: String
  }

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    this.element.classList.add(CSS_CLASSES.initialized)
    this.slider = new Flickity(this.element, {
      autoPlay: this.autoplayDelayValue || DEFAULT_AUTOPLAY_DELAY,
      cellAlign: this.alignment,
      contain: true,
      arrowShape: "M89.0625 50.0000a4.6875 4.6875 0 0 1 -4.6875 4.6875H26.9531l20.1250 20.1211a4.6875 4.6875 0 0 1 -6.6406 6.6406l-28.1250 -28.1250a4.6875 4.6875 0 0 1 0.0000 -6.6406l28.1250 -28.1250a4.6875 4.6875 0 0 1 6.6406 6.6406L26.9531 45.3125H84.3750A4.6875 4.6875 0 0 1 89.0625 50.0000Z"
    })
  }

  disconnect() { this.slider?.destroy() }

  get alignment() {
    if (!this.alignmentValue) { return DEFAULT_ALIGNMENT}
    return this.alignmentValue
  }

  get sliderElement() {
    if (this.hasSliderTarget) { return this.sliderTarget }
    return this.element
  }
}
