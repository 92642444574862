import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    content: String,
    confirmation: String,
    confirmationCssClass: String
  }
  static targets = ["button", "content"]

  connect() {
    this.originalButtonText = this.buttonTarget.innerText
  }

  copyToClipboard(event) {
    event.stopPropagation()
    event.preventDefault()

    try { navigator.clipboard.writeText(this.value) } catch (error) {}
    this.showConfirmation()
  }

  showConfirmation() {
    if (!this.hasButtonTarget) { return }

    if (this.confirmationValue) { this.buttonTarget.innerText = this.confirmationValue }
    if (this.confirmationCssClassValue) { this.buttonTarget.classList.add(this.confirmationCssClassValue) }

    window.setTimeout(() => { this.restoreInitialButtonState() }, 2500)
  }

  restoreInitialButtonState() {
    if (this.confirmationValue) { this.buttonTarget.innerText = this.originalButtonText }
    if (this.confirmationCssClassValue) { this.buttonTarget.classList.remove(this.confirmationCssClassValue) }
  }

  get value() {
    if (this.contentValue) { return this.contentValue }
    if (this.hasContentTarget) { return this.contentTarget.innerText }

    return this.element.innerText
  }
}
