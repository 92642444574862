import { cookiesAreEnabled } from "../cookies";

const MAX_CHECKED_RETRIES = 10
let checked_retries = 0

let facebookPixelId = null
let facebookAnalyticsInitialized = false

// Inerhal helpers

const loadFacebookScript = (f,b,e,v,n,t,s) => {
  if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version="2.0";
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)
}

// Public API

export const setFacebookPixelId = (value) => { facebookPixelId = value }

export const startFacebookTracking = () => {
  if (!facebookPixelId) { return }
  if (facebookAnalyticsInitialized) { return }

  loadFacebookScript(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js")
  checked_fbq("init", facebookPixelId);
  checked_fbq("track", "PageView");

  facebookAnalyticsInitialized = true
}

export const checked_fbq = (a, b, c) => {
  if (!facebookAnalyticsInitialized) { return }
  if (!cookiesAreEnabled()) { return }

  checked_retries += 1
  if(checked_retries > MAX_CHECKED_RETRIES) { return }

  if(typeof window.fbq === "function") {
    window.fbq(a, b, c)
  } else {
    window.setTimeout(checked_fbq, 500, a, b, c)
  }
}
