import { Controller } from "@hotwired/stimulus"

import {
  checked_fbq,
  setFacebookPixelId,
  startFacebookTracking
} from "../src/analytics/facebook"

import {
  setFocalAnalyticsId,
  startConsentFocalAnalytics,
  startGlobalFocalAnalytics
} from "../src/analytics/focal"

import {
  disableAllGoogleTrackers,
  enableAllGoogleTrackers,
  honorExistingOptOut,
  gtag,
  optOut,
  setGoogleAnalyticsId,
  setGoogleTagManagerId,
  startGoogleAnalytics,
  startGTM
} from "../src/analytics/google"

import {
  disableMatomoAnalytics,
  setMatomoAnalyticsUrl,
  startMatomoAnalytics,
} from "../src/analytics/matomo"

import { disableCookies, enableCookies } from "../src/cookies"
import { buildCookieConsent } from "../src/cookie_consent"

const disableAnalytics = () => {
  // Disable all GA trackers on the page.
  disableAllGoogleTrackers()
  // Disable existing Matomo analytics
  disableMatomoAnalytics()
  // Remove cookies set by GA
  disableCookies()
}

const enableAnalytics = () => {
  enableAllGoogleTrackers()
  enableCookies()

  if (typeof startGTM === "function") { try { startGTM() } catch (e) {} }
  if (typeof startGoogleAnalytics === "function") { try { startGoogleAnalytics() } catch (e) {} }
  if (typeof startFacebookTracking === "function") { try { startFacebookTracking() } catch (e) {} }
  if (typeof startMatomoAnalytics === "function") { try { startMatomoAnalytics() } catch (e) {} }
  if (typeof startConsentFocalAnalytics === "function") { try { startConsentFocalAnalytics() } catch (e) {} }
}

export default class extends Controller {
  static values = {
    cookieColorButton: String,
    cookieColorPopup: String,
    cookieTranslations: Object,
    facebookPixelId: String,
    focalAnalyticsId: String,
    googleAnalyticsId: String,
    googleTagManagerId: String,
    matomoUrl: String
  }

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    if (this.facebookPixelIdValue) { setFacebookPixelId(this.facebookPixelIdValue) }
    if (this.googleTagManagerIdValue) { setGoogleTagManagerId(this.googleTagManagerIdValue) }
    if (this.matomoUrlValue) { setMatomoAnalyticsUrl(this.matomoUrlValue) }

    if (this.googleAnalyticsIdValue) {
      setGoogleAnalyticsId(this.googleAnalyticsIdValue)
      honorExistingOptOut()
    }

    if (this.focalAnalyticsIdValue) {
      setFocalAnalyticsId(this.focalAnalyticsIdValue)
      startGlobalFocalAnalytics()
    }

    this.cc = {}
    buildCookieConsent(this.cc)
    this.showBanner()
  }

  // Public API

  optOut(event) {
    if (typeof event.preventDefault === "function") { event.preventDefault() }

    optOut()
    alert("Google Analytics wurde deaktiviert.")
  }

  trackConversion(event) {
    if (!event.params.gtmLabel) { return }

    gtag("event", "conversion",{
      "send_to": event.params.gtmLabel
    })
  }

  trackFacebookEvent(event) {
    const action = "track"
    const category = event.params.category
    const options = event.params.options

    checked_fbq(action, category, options)
  }

  // Internal actions

  showBanner() {
    if (typeof this.cc.initialise !== 'function') { return }

    this.cc.initialise({
      "palette": {
        "popup": { "background": this.cookieColorPopupValue },
        "button": { "background": this.cookieColorButtonValue }
      },
      "type": "opt-in",
      "content": {
        message: this.cookieTranslationsValue.message,
        deny: this.cookieTranslationsValue.buttons.deny,
        allow: this.cookieTranslationsValue.buttons.allow,
        link: this.cookieTranslationsValue.links.privacy,
        policy: this.cookieTranslationsValue.buttons.policy,
        // page_path('privacy')
        href: "/privacy",
      },
      elements: {
        deny: '<a aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny">{{deny}}</a>',
      },
      onInitialise: function(status) { if(this.hasConsented()) { enableAnalytics() } },
      onStatusChange: function(status, chosenBefore) {
        this.hasConsented() ? enableAnalytics() : disableAnalytics()
      },
      onRevokeChoice: function() { disableAnalytics() },
    })
  }
}
