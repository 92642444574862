import { Controller } from "@hotwired/stimulus"
import Flickity from "flickity"
import { throttle } from "../src/throttle"

const CSS_CLASSES = {
  expanded: "is--expanded",
  initialized: "is--initialized",
  transitioning: "is--transitioning",
  movieGrid: "movie-grid"
}

// The minimum amount deltaX has to be above deltaY to determine whether a wheel event was horizontal.
const HORIZONTAL_WHEEL_DELTA_DIFFERENCE = 50
// Pagination is triggered <slide window width> * PAGINATION_WINDOW_SIZE_OFFSET before reaching the end of the slider.
const PAGINATION_WINDOW_OFFSET_FACTOR = 2

export default class extends Controller {
  static targets = ["paginationTrigger", "slider", "wrapper"]

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    this.isLoadingNewTiles = false
    this.isExpanded = this.wrapperElement?.classList?.contains(CSS_CLASSES.expanded)
    this.expansionFunction = () => this.expand()
    this.collapseFunction = () => this.collapse()
    this.throttledScrollHandlerFunction = throttle(() => this.handleSliderScroll(), 100)
    this.throttledWheelHandlerFunction = throttle((event) => this.handleHorizontalMouseWheel(event), 100).bind(this)

    // If we get here after a back navigation, we need to restore the proper state.
    if (this.isExpanded) {
      this.expand()
    } else {
      this.collapse()
    }

    this.sliderElement.addEventListener("ajax:success", (event) => { this.updateSliderAfterPagination(event.detail[0]) })
    this.wrapperElement?.addEventListener("wheel", this.throttledWheelHandlerFunction)
  }

  disconnect() {
    this.slider?.destroy()
    this.wrapperElement?.removeEventListener("wheel", this.throttledWheelHandlerFunction)
  }

  // Slider lifecycle

  initializeSlider() {
    this.wrapperElement?.classList.add(CSS_CLASSES.initialized)

    this.slider = new Flickity(this.sliderElement, {
      cellAlign: "left",
      contain: true,
      wrapAround: false
    })

    this.slider.on("scroll", () => this.throttledScrollHandlerFunction())
  }

  updateSliderAfterPagination(newData) {
    const fragment = document.createRange().createContextualFragment(newData)
    const newTiles = fragment.querySelectorAll(".movie-tile-wrapper")
    if (!newTiles.length) { return }

    newTiles.forEach(element => { element.classList.add("is--loading") })

    if (this.isExpanded) {
      if (this.hasPaginationTriggerTarget) { this.paginationTriggerTarget.remove() }
      this.sliderElement.append(...newTiles)
    } else {
      if (this.hasPaginationTriggerTarget) { this.slider.remove(this.paginationTriggerTarget) }
      this.slider.append(newTiles)
    }

    this.isLoadingNewTiles = false

    setTimeout(() => {
      this.sliderElement.querySelectorAll(".is--loading").forEach(element => { element.classList.remove("is--loading") })
    }, 0)
  }

  handleHorizontalMouseWheel(event) {
    const isHorizontal = Math.abs(event.deltaX) - Math.abs(event.deltaY) > HORIZONTAL_WHEEL_DELTA_DIFFERENCE
    if (!isHorizontal) { return }

    if(event.deltaX < 0) {
      this.slider.previous()
    } else {
      this.slider.next()
    }

    this.handleSliderScroll()
  }

  handleSliderScroll() {
    if (this.isLoadingNewTiles) { return }
    if (!this.hasPaginationTriggerTarget) { return }

    // X is moving out to the left, so it decreases constantly.
    const currentPosition = this.slider.dragX * -1
    const totalWidth = this.slider.slideableWidth
    const slideWidth = this.slider.size.innerWidth
    const paginationOffset = totalWidth - (slideWidth * PAGINATION_WINDOW_OFFSET_FACTOR)

    if (currentPosition < paginationOffset) { return }

    this.isLoadingNewTiles = true
    this.paginationTriggerTarget.querySelector("a").click()
  }

  // Collapse / Expansion handling

  toggleExpansion() {
    const targetFunction = this.isExpanded ? this.collapseFunction : this.expansionFunction

    this.sliderElement.addEventListener("transitionend", targetFunction)
    this.sliderElement.classList.add(CSS_CLASSES.transitioning)
  }

  collapse() {
    this.sliderElement.classList.remove(CSS_CLASSES.movieGrid)
    this.wrapperElement?.classList?.remove(CSS_CLASSES.expanded)
    this.initializeSlider()
    this.isExpanded = false
    this.removeTransitionHandler(this.collapseFunction)
  }

  expand() {
    this.slider?.destroy()
    this.sliderElement.classList.add(CSS_CLASSES.movieGrid)
    this.wrapperElement?.classList?.add(CSS_CLASSES.expanded)
    this.isExpanded = true
    this.removeTransitionHandler(this.expansionFunction)
  }

  removeTransitionHandler(targetFunction) {
    this.sliderElement.removeEventListener("transitionend", targetFunction)
    this.sliderElement.classList.remove(CSS_CLASSES.transitioning)
  }

  // Getters

  get sliderElement() {
    if (this.hasSliderTarget) { return this.sliderTarget }
    return this.element
  }

  get wrapperElement() {
    if (this.hasWrapperTarget) { return this.wrapperTarget }
  }
}
