import { Controller } from "@hotwired/stimulus"

const TARGET_SELECTOR = `[data-target="form-utm-tags"]`

export default class extends Controller {
  static targets = ["input"]
  static values = { tagNames: Array }

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    this.storeTags()
  }

  storeTags() {
    if (!this.tagNames) { return }

    const url = new URL(window.location.href)
    const writtenTags = []

    for (const [key, value] of url.searchParams) {
      const tagName = key.toLowerCase()
      if (!this.tagNames.includes(tagName)) { continue }
      if (!value) { continue }

      try {
        localStorage.setItem(tagName, value)
        writtenTags.push(tagName)
      } catch(e) {}
    }

    // If nothing was written, we just keep the old set of tags in localStorage.
    if (!writtenTags.length) {
      this.writeTagsToFormFieldTargets()
      return
    }

    // If something was written, determine which tag names were not written to and unset them. This ensures we only store the new set of tags wihtout remnants of previous campaigns.
    const untouchedTagNames = this.tagNames.filter(function(tagName) { return !writtenTags.includes(tagName) })
    for (const tagName of untouchedTagNames) {
      try {
        localStorage.removeItem(tagName)
      } catch(e) {}
    }

    this.writeTagsToFormFieldTargets()
  }

  writeTagsToFormFieldTargets() {
    if (this.hasInputTargets) { return }

    const tagData = {}

    for (const tagName of this.tagNames) {
      try {
        const data = localStorage.getItem(tagName)
        if (!data) { continue }

        tagData[tagName] = data
      } catch(e) {}
    }

    const stringifiedTagData = JSON.stringify(tagData)

    this.inputTargets.forEach(target => { target.value = stringifiedTagData })
  }

  get tagNames() { return this.tagNamesValue }
}
