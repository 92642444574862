import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdownContent"]

  connect() {
    this.closeFunction = (event) => { this.closeDropdown(event) }
    this.collapseTriggerFunction = () => { this.collapseTrigger() }
  }

  expandTrigger() {
    this.element.classList.add("is--active")

    if (!this.element.classList.contains("is--open")) {
      document.addEventListener("click", this.closeFunction)
      document.addEventListener("mousemove", this.closeFunction)
    }
  }

  openDropdown() {
    this.element.classList.add("is--open")
    document.removeEventListener("mousemove", this.closeFunction)
  }

  closeDropdown(event) {
    if (this.element.contains(event.target)) { return }

    document.removeEventListener("click", this.closeFunction)

    // If the User didn't click on the dropdown trigger but rather moved the mouse away from it immediately, .is--open is never applied and thus there is no animation to finish when we remove it.
    if (this.element.classList.contains("is--open")) {
      this.dropdownContentTarget.addEventListener("transitionend", this.collapseTriggerFunction)
      this.element.classList.remove("is--open")
    } else {
      this.collapseTrigger()
    }
  }

  collapseTrigger() {
    this.dropdownContentTarget.removeEventListener("transitionend", this.collapseTriggerFunction)
    this.element.classList.remove("is--active")
  }
}
