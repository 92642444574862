import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static values = {
    options: Object,
    autoSubmit: Boolean
  }

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    this.originalValue = this.element.value
    this.selectedValue = this.element.value

    this.options = {
      ...this.optionsValue,
      render: {
        no_results: () => `<div class="no-results">Leider keine Ergebnisse</div>`,
      },
      onChange: function(value) { this.handleChange(value) }.bind(this),
      onBlur: function() { this.handleBlur() }.bind(this)
    }

    this.select = new TomSelect(this.element, this.options)
  }

  disconnect() { this.select?.destroy() }

  handleChange(value) {
    if (!value) { return }

    this.selectedValue = value
    this.select.blur()
  }

  handleBlur() {
    // Ensure the display reverts to the original value if the input was cleared and then nothing was selected.
    this.select.setValue(this.selectedValue, true)

    if (this.shouldAutoSubmit && this.selectionHasChanged()) { this.form.requestSubmit() }
  }

  selectionHasChanged() { return this.originalValue != this.selectedValue }

  get activeTomSelectInputWrapper() { return this.element.querySelector(".ts-wrapper.input-active") }
  get form() { return this.element.closest("form") }
  get shouldAutoSubmit() { return !!this.autoSubmitValue }
}
