import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdown", "dropdownTrigger", "dropdownWrapper"]

  connect() {
    if (!this.hasDropdownWrapperTarget) { return }

    this.closeFunction = (event) => { this.closeDropdown(event) }
  }

  openDropdown() {
    if (!this.hasDropdownWrapperTarget) { return }

    this.dropdownWrapperTarget.classList.add("is--active")
    this.dropdownTriggerTarget.classList.add("is--active")
    document.addEventListener("mousemove", this.closeFunction)
  }

  closeDropdown(event) {
    if (!this.hasDropdownTarget) { return }
    if (this.dropdownTriggerTarget.contains(event.target) || this.dropdownTarget.contains(event.target)) { return }

    this.dropdownWrapperTarget.classList.remove("is--active")
    this.dropdownTriggerTarget.classList.remove("is--active")
    document.removeEventListener("mousemove", this.closeFunction)
  }
}
