let focalAnalyticsId = null
let focalAnalyticsInitialized = false

// Internal helpers
const loadFocalScript = (c, i) => {
  var t = "https://ws.focal-analytics.de/t/";
  var fc = (c.match(new RegExp('(^| )_focal_fptc=([^;]+)')) || [0, 0, false])[2];
  var focalQueryString = "?y=" + screen.height.toString() + "&u=" + encodeURIComponent(window.location.href);
  if (fc) focalQueryString += "&p=" + fc;
  if (document.referrer != "") { focalQueryString += '&r=' + encodeURIComponent(document.referrer); }

  var focalScript = document.createElement("script");
  focalScript.src = t + i + "/" + screen.width.toString() + focalQueryString;
  focalScript.async = true;
  focalScript.type = "text/javascript";
  document.getElementsByTagName("head")[0].appendChild(focalScript);
}

// Public API

export const setFocalAnalyticsId = (value) => { focalAnalyticsId = value }
export const startConsentFocalAnalytics = () => { window._Focal._setCookie() }

export const startGlobalFocalAnalytics = () => {
  if (!focalAnalyticsId) { return }
  if (focalAnalyticsInitialized) { return }

  window._FocalQueue = window._FocalQueue || []

  loadFocalScript(document.cookie, focalAnalyticsId)
}
