import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import Fingerprint2 from "fingerprintjs2"

const FINGERPRINT_OPTIONS = {
  excludes: {
    userAgent: true,
    enumerateDevices: true
  }
}

const RESPONSE_DEVICE_UNKNOWN = "device_unknown"
const RESPONSE_DEVICE_REGISTERED = "device_registered"

export default class extends Controller {
  static targets = ["form", "spinner", "uidInput"]
  static values = {
    checkUrl: String,
    orderUrl: String,
  }

  connect() {
    Fingerprint2.get(FINGERPRINT_OPTIONS, (components) => {
      const values = components.map(component => component.value)
      const deviceFingerprint = Fingerprint2.x64hash128(values.join(''), 31)

      this.checkDeviceFingerprint(deviceFingerprint)
    })
  }

  async checkDeviceFingerprint(deviceFingerprint) {
    const url = `${this.checkUrlValue}/${deviceFingerprint}`
    const response = await fetch(url)
    if (!response.ok) { return }

    const responseData = await response.json()
    this.parseDeviceCheckResult(responseData.status, deviceFingerprint)
  }

  parseDeviceCheckResult(status, deviceFingerprint) {
    if(status == RESPONSE_DEVICE_UNKNOWN) {
      this.spinnerTarget.classList.add("is--hidden")
      this.formTarget.classList.remove("is--hidden")
      this.uidInputTarget.value = deviceFingerprint

    } else if(status == RESPONSE_DEVICE_REGISTERED) {
      Turbo.visit(this.orderUrlValue)

    } else {
      // TODO
      console.log('unknown reply')
    }
  }
}
