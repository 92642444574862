const baseInputCss = "padding: 15px 23px; font-size: 16px; font-weight: 400; line-height: 1.4; color: #fff; background-color: #2F3242; background-clip: padding-box; border: 0 solid #ced4da; appearance: none; border-radius: 0.375rem; transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; ";
const baseInputFocusCss = "padding: 15px 23px; font-size: 16px; font-weight: 400; line-height: 1.4; color: #fff; background-color: #2F3242; background-clip: padding-box; border: 0 solid #ced4da; appearance: none; border-radius: 0.375rem; transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; outline: none; ";

const supportedCardtypes = ["V", "M", "A"]

export const buildFieldConfiguration = (locale) => {
  return {
    fields: {
      // cardtype: {
      //   selector: "cardtype", // put name of your div-container here
      //   cardtypes: supportedCardtypes, // define possible cardtypes in PAYONE iFrame
      //   style: baseInputCss + "width: 100%;",
      //   styleFocus: baseInputFocusCss + "width: 100%;",
      // },
      cardtype: {
        selector: "cardtype",
        cardtypes: supportedCardtypes
      },
      cardpan: {
        selector: "cardpan", // put name of your div-container here
        type: "text", // text (default), password, tel
        style: baseInputCss + "width: 100%;",
        styleFocus: baseInputFocusCss + "width: 100%;",
      },
      cardcvc2: {
        selector: "cardcvc2", // put name of your div-container here
        type: "password", // select(default), text, password, tel
        size: "4",
        maxlength: "4",
        style: baseInputCss + "width: 80px;",
        styleFocus: baseInputFocusCss + "width: 80px;",
      },
      cardexpiremonth: {
        selector: "cardexpiremonth", // put name of your div-container here
        type: "select", // select(default), text, password, tel
        style: baseInputCss + "width: 70px;",
        styleFocus: baseInputFocusCss + "width: 70px;",
        size: "2",
        maxlength: "2",
        iframe: {
          width: "81px"
        }
      },
      cardexpireyear: {
        selector: "cardexpireyear", // put name of your div-container here
        type: "select", // select(default), text, password, tel
        style: baseInputCss + "width: 100px;",
        styleFocus: baseInputFocusCss + "width: 100px;",
        iframe: {
          width: "100px"
        }
      }
    },
    defaultStyle: {
      iframe: {
        height: "53px",
        width: "auto"
      }
    },
    autoCardtypeDetection: {
      supportedCardtypes: supportedCardtypes,
      callback: function(detectedCardtype) {
        // console.log(`Detected card type: ${detectedCardtype}`)
      },
  },
    error: "errorOutput", // area to display error-messages (optional)
    language: Payone.ClientApi.Language[locale] // Language to display error-messages
  }
}

export const buildRequestConfiguration = (options) => {
  const mode = options["mode"] || "test"
  const merchantId = options["merchantId"]
  const accountId = options["accountId"]
  const portalId = options["portalId"]
  const hash = options["hash"]

  return {
    request: "creditcardcheck",
    responsetype: "JSON",
    mode: mode,
    mid: merchantId,
    aid: accountId,
    portalid: portalId,
    encoding: "UTF-8",
    storecarddata: "yes",
    hash: hash
  }
}
