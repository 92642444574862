import { Controller } from "@hotwired/stimulus"
import Flickity from "flickity"

export default class extends Controller {
  static targets = ["slider"]

  connect() {
    this.slider = new Flickity(this.element, {
      cellAlign: "left",
      contain: true
    })
  }

  disconnect() { this.slider?.destroy() }

  get sliderElement() {
    if (this.hasSliderTarget) { return this.sliderTarget }
    return this.element
  }
}
