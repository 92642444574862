import { Controller } from "@hotwired/stimulus"
import { loadApiEndpointIfNotAlready } from "../src/hcaptcha-loader"

export default class extends Controller {
  static targets = ["captcha"]
  static values = { sitekey: String }

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    loadApiEndpointIfNotAlready({ apiEndpoint: "https://hcaptcha.com/1/api.js" })
      .then(this.showCaptcha.bind(this))
      .catch((err) => console.error("[X] Failed to load hCaptcha", err))
  }

  disconnect() {
    if (!this.widgetId) { return }

    window.hcaptcha.reset(this.widgetId)
    this.captchaTarget.classList.add("is--hidden")
  }

  showCaptcha() {
    this.captchaTarget.classList.remove("is--hidden")
    this.widgetId = window.hcaptcha.render(this.captchaTarget, { sitekey: this.sitekeyValue })
  }
}
