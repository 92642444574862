import { Controller } from "@hotwired/stimulus"

const TOP_PADDING_IN_VIEWPORT_HEIGHT_PERCENTAGE = 0.25

export default class extends Controller {
  connect() {
    const elementTop = this.element.getBoundingClientRect()?.top

    // Fallback if we can't determine a pixel value for the top of the targeted element.
    if (!elementTop) {
      this.scrollElementIntoView()
      return
    }

    const viewportHeight = document.documentElement.clientHeight
    const targetY = elementTop - (viewportHeight * TOP_PADDING_IN_VIEWPORT_HEIGHT_PERCENTAGE)
    this.scrollToPosition(targetY)
  }

  scrollElementIntoView() {
    window.setTimeout(
      () => { this.element.scrollIntoView({ behavior: "smooth" }) },
      100
    )
  }

  scrollToPosition(targetY) {
    window.setTimeout(
      () => { window.scrollTo({ top: targetY, behavior: "smooth", }) },
      100
    )
  }
}
