// Used to prevent loading PayOne JS files multiple times.
import hashCode from "./minimal-hash"

let externalResolveFunction;
let externalRejectFunction;
const externallyCallablePromise = new Promise((resolve, reject) => {
  externalResolveFunction = resolve
  externalRejectFunction = reject
})

const PAYONE_SCRIPT_URLS = [
  "https://secure.pay1.de/client-api/js/v1/payone_hosted_min.js",
]
const PAYONE_SCRIPT_HASHES = PAYONE_SCRIPT_URLS.map(url => hashCode(url))

const addScriptTag = (scriptUrl) => {
  return new Promise(function(resolve, reject) {
    const scriptHash = hashCode(scriptUrl)
    const scriptId = `pay1-script-${hashCode(scriptUrl)}`

    if (document.getElementById(scriptId)) {
      resolve(`Script element for ${scriptUrl} already exists`)
      return
    }

    let scriptTag = document.createElement("script")
    scriptTag.id = scriptId
    scriptTag.src = scriptUrl
    scriptTag.defer = true
    scriptTag.async = true
    scriptTag.onerror = () => { reject(scriptUrl) }
    scriptTag.onload = () => {
      if (!window.loadedPayoneScriptHashes) { window.loadedPayoneScriptHashes = {} }
      window.loadedPayoneScriptHashes[scriptHash] = true
      resolve(scriptUrl)
    }

    document.body.appendChild(scriptTag)
  })
}

export const loadPayOneScripts = () => {
  // Initial check to see if the scripts are already loaded
  if (isFullyLoaded()) { return Promise.resolve("Pay1-Scripts already previously loaded!") }

  const promiseData = PAYONE_SCRIPT_URLS.map(url => addScriptTag(url))

  Promise.all(promiseData).then(() => {
    externalResolveFunction("Pay1-Scripts loaded!")
  }).catch((scriptTag) => {
    console.error(`Failed to load Pay1-Script: ${scriptTag}`)
    externalRejectFunction(`Failed to load Pay1-Script: ${scriptTag}`)
  })

  return externallyCallablePromise
}

const isFullyLoaded = () => {
  if (!window.loadedPayoneScriptHashes) { return false }

  for (let index = 0; index < PAYONE_SCRIPT_HASHES.length; index++) {
    if (!window.loadedPayoneScriptHashes[PAYONE_SCRIPT_HASHES[index]]) { return false }
  }

  return true
}
