import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const initialScrollBehaviour = document.documentElement.style.scrollBehavior

    document.documentElement.style.scrollBehavior = "auto"
    window.scrollTo(0, 0)

    setTimeout(() => { document.documentElement.style.scrollBehavior = initialScrollBehaviour }, 50)
  }
}
