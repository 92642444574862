import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["bicInput", "ibanInput"]

  async lookupBic() {
    const iban = this.ibanInputTarget.value
    if (!iban) { return }

    const url = `https://openiban.com/validate/${iban}?validateBankCode=true&getBIC=true`
    const response = await fetch(url)
    if (!response.ok) { return }

    const responseData = await response.json()

    if (!responseData?.valid)  { return }
    if (!responseData?.bankData?.bic)  { return }

    this.bicInputTarget.value = responseData?.bankData?.bic
  }
}
