import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { message: String }

  postMessage() {
    if (!window.kodMessageHandler) {
      console.log("No kodMessageHandler present, can't post to it.")
      return
    }

    window.kodMessageHandler.postMessage(this.messageValue)
  }
}
