import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    date: String
  }

  connect() {
    this.initializeStore()
    this.setOrderPurchaseDate()
  }

  initializeStore() {
    if (window.store) { return }

    window.store = {
      order: {
        data: {}
      },
      user: {
        data: {}
      }
    }
  }

  setOrderPurchaseDate() { window.store.order.data.purchased_at = JSON.parse(this.dateValue) }
}
