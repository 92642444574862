import { Controller } from "@hotwired/stimulus"

const CSS_CLASSES = { expanded: "is--expanded" }

export default class extends Controller {
  static targets = ["icon", "content"]

  connect() {
    this.expanded = this.element.classList.contains(CSS_CLASSES.expanded)
  }

  updateView() {
    this.element.classList.toggle(CSS_CLASSES.expanded, this.expanded)
  }

  toggle() {
    this.expanded = !this.expanded
    this.updateView()
  }
}
