import { cookiesAreEnabled } from "../cookies"

let matomoAnalyticsUrl = null
let matomoAnalyticsInitialized = false

// Internal helpers
const loadMatomoScript = () => {
  var u=matomoAnalyticsUrl;
  _paq.push(["setTrackerUrl", u+"matomo.php"]);
  _paq.push(["setSiteId", "1"]);
  var d=document, g=d.createElement("script"), s=d.getElementsByTagName("script")[0];
  g.async=true; g.src=u+"matomo.js"; s.parentNode.insertBefore(g,s);
}

// Public API

export const setMatomoAnalyticsUrl = (value) => { matomoAnalyticsUrl = value }

// We"re not using checked_paq here since cookiesAreEnabled() might already be false.
// If we run into an error here, Matomo wasn"t loaded in the first place so we don"t have to opt out.
export const disableMatomoAnalytics = () => {
  try { _paq.push(["forgetCookieConsentGiven"]) } catch(e) {}
}

export const checked_paq = (optionsArray) => {
  if(cookiesAreEnabled()) { return }

  let _paq = window._paq = window._paq || []
  _paq.push(optionsArray)
}

export const startMatomoAnalytics = () => {
  if (matomoAnalyticsInitialized) { return }
  if (!matomoAnalyticsUrl) { return }

  var _paq = window._paq = window._paq || []
  _paq.push(["requireCookieConsent"])
  _paq.push(["rememberCookieConsentGiven"])
  _paq.push(["trackPageView"])
  _paq.push(["enableLinkTracking"])
  loadMatomoScript()

  matomoAnalyticsInitialized = true
}
